<template>
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.8 67.5H12.8C11.9705 67.5 11.3 66.828 11.3 66V24C11.3 23.172 11.9705 22.5 12.8 22.5H60.8C61.6295 22.5 62.3 23.172 62.3 24V66C62.3 66.828 61.6295 67.5 60.8 67.5Z"
      fill="#86EFAC"
      style="fill: #86efac; fill: color(display-p3 0.5255 0.9373 0.6745); fill-opacity: 1" />
    <path d="M44.3 67.5H29.3V48C29.3 47.172 29.972 46.5 30.8 46.5H42.8C43.628 46.5 44.3 47.172 44.3 48V67.5Z" fill="url(#paint0_linear_1073_4131)" style="" />
    <path
      opacity="0.05"
      d="M60.8 22.5H12.8C11.9705 22.5 11.3 23.172 11.3 24V37.386C11.861 37.455 12.4295 37.5015 13.0115 37.5015H13.2305C16.0895 37.4895 18.806 36.6615 21.0995 35.1315C23.4275 36.6795 26.123 37.4955 28.9296 37.4955C31.7765 37.4955 34.4975 36.6675 36.806 35.1255C39.14 36.6825 41.837 37.5015 44.6361 37.5015C45.404 37.5015 46.181 37.4385 46.9506 37.3125C48.965 36.9825 50.8505 36.2415 52.511 35.1345C54.797 36.657 57.5105 37.4985 60.2585 37.4985L60.6875 37.503C61.2335 37.503 61.7705 37.4595 62.3015 37.395V23.9985C62.3015 23.1705 61.631 22.4985 60.8015 22.4985L60.8 22.5Z"
      fill="black"
      style="fill: black; fill: black; fill-opacity: 1" />
    <path
      d="M2.30005 24.036C2.44105 29.94 7.29805 34.611 13.2185 34.5C16.2155 34.4895 19.0205 33.342 21.092 31.2795C21.5045 31.686 21.9485 32.061 22.421 32.4015C26.9075 35.6265 32.999 35.037 36.8 31.275C37.214 31.683 37.661 32.0595 38.1365 32.4015C40.058 33.7815 42.314 34.5045 44.6345 34.5045C45.2405 34.5045 45.8525 34.455 46.4645 34.3545C48.782 33.975 50.8745 32.9025 52.5095 31.2825C54.56 33.3255 57.3515 34.5015 60.257 34.5015C60.284 34.5015 60.3095 34.5015 60.338 34.5015C66.212 34.6965 71.159 29.9415 71.3015 24.0375C71.3015 24.0255 71.297 24.0135 71.297 24.0015H2.30455C2.30455 24.0135 2.30005 24.024 2.30005 24.036Z"
      fill="url(#paint1_linear_1073_4131)"
      style="" />
    <path
      d="M71.2958 24C71.2958 23.7045 71.2133 23.4135 71.0483 23.1675L59.0483 5.1675C58.7693 4.7505 58.3013 4.5 57.8003 4.5H15.8003C15.2992 4.5 14.8312 4.7505 14.5522 5.1675L2.55219 23.1675C2.38719 23.4135 2.30469 23.7045 2.30469 24H71.2958Z"
      fill="url(#paint2_linear_1073_4131)"
      style="" />
    <defs>
      <linearGradient id="paint0_linear_1073_4131" x1="36.8" y1="46.5" x2="36.8" y2="67.4998" gradientUnits="userSpaceOnUse">
        <stop stop-color="#22C55E" style="stop-color: #22c55e; stop-color: color(display-p3 0.1333 0.7725 0.3686); stop-opacity: 1" />
        <stop offset="1" stop-color="#14A84A" style="stop-color: #14a84a; stop-color: color(display-p3 0.0778 0.6597 0.292); stop-opacity: 1" />
      </linearGradient>
      <linearGradient id="paint1_linear_1073_4131" x1="36.8008" y1="24.0015" x2="36.8008" y2="34.5072" gradientUnits="userSpaceOnUse">
        <stop stop-color="#22C55E" style="stop-color: #22c55e; stop-color: color(display-p3 0.1333 0.7725 0.3686); stop-opacity: 1" />
        <stop offset="1" stop-color="#14A84A" style="stop-color: #14a84a; stop-color: color(display-p3 0.0778 0.6597 0.292); stop-opacity: 1" />
      </linearGradient>
      <linearGradient id="paint2_linear_1073_4131" x1="36.8003" y1="4.5" x2="36.8003" y2="24" gradientUnits="userSpaceOnUse">
        <stop stop-color="#36DA73" style="stop-color: #36da73; stop-color: color(display-p3 0.2136 0.8568 0.4503); stop-opacity: 1" />
        <stop offset="1" stop-color="#22C55E" style="stop-color: #22c55e; stop-color: color(display-p3 0.1333 0.7725 0.3686); stop-opacity: 1" />
      </linearGradient>
    </defs>
  </svg>
</template>
